/**
 * Returns object created from form
 * @param {object} object with form fields
 * @returns {object}
 */

export const getFormData = formData => {
  return {
    name: formData.name.value || '',
    email: formData.email.value || '',
    phone: formData.phone.value || '',
    message: formData.message.value || ''
  }
}
