import React, { useEffect, useState } from 'react'

import Input from '../UI/Input/Input'
import TextArea from '../UI/Textarea/Textarea'
import { validate } from '../../utils/form-validator'
import { getFormData } from '../../utils/getFormData'
import { scrollToTop } from '../../utils/scroll-to-top'
import classes from './Form.module.scss'

/**
 * Returns form controls
 * @param no needs
 * @returns {objects} Object that contain form controls (name, email, phone, message).
 */

const createFormControls = data => {
  const formControls = {
    name: {
      label: '',
      value: '',
      placeholder: '',
      isValid: true,
      validation: null
    },
    email: {
      label: '',
      value: '',
      placeholder: '',
      required: true,
      isValid: true,
      errorMessage: '',
      validation: {
        onBlur: {
          isEmail: true
        },
        onChange: {
          required: true
        }
      }
    },
    phone: {
      label: '',
      value: '',
      placeholder: '',
      isValid: true,
      validation: null
    },
    message: {
      label: '',
      value: '',
      placeholder: '',
      isValid: true,
      validation: null
    }
  }

  Object.keys(formControls).map(controlName => {
    formControls[controlName].label = data[controlName].label || ''
    formControls[controlName].placeholder = data[controlName].placeholder || ''
  })

  return formControls
}

/**
 * Contact form component
 */

const Form = ({ title, fields, buttonName, getStatus }) => {
  const [formControls, setContactForm] = useState(createFormControls(fields))

  const formResetHandler = event => {
    event.preventDefault()

    // scrolled up after reset from
    scrollToTop(50, 10)

    setContactForm(formControls)
  }

  useEffect(() => {
    Array.from(document.querySelectorAll('.contact-us-link'))
      .map(link => link.addEventListener('click', formResetHandler))

    //  allow :active styles to work in CSS on a page in Mobile Safari
    document.addEventListener('touchstart', function () {}, true)
    return () => {
      Array.from(document.querySelectorAll('.contact-us-link'))
        .map(link => link.removeEventListener('click', formResetHandler))
      document.removeEventListener('touchstart', function () {})
    }
  }, [])

  /**
    * handle change event inputs and textarea
    * @param {srting} Inputs or textarea value.
    * @param {srting} Inputs or textarea name.
    */

  const changeHandler = (value, controlName) => {
    const updatedControls = { ...formControls }
    const control = { ...updatedControls[controlName] }
    const { isValid, errorMessage } = validate(
      value,
      control.validation && control.validation.onChange,
      fields.email
    )
    control.value = value
    control.isValid = isValid
    control.errorMessage = errorMessage

    updatedControls[controlName] = control
    setContactForm(updatedControls)
  }

  /**
    * handle onBlur event
    * @param {srting} Input value
    */

  const onblurHandler = value => {
    const updatedControls = { ...formControls }
    const control = { ...updatedControls['email'] }
    const { isValid, errorMessage } = validate(
      value,
      {
        ...control.validation.onBlur
      },
      fields.email
    )
    control.isValid = isValid
    control.errorMessage = errorMessage

    updatedControls['email'] = control
    setContactForm(updatedControls)
  }

  /**
    * Returns JSX (input elements).
    * @return {JSX | array} Array of the input elements.
    */

  const renderControlsInput = () => {
    return Object.keys(formControls).map((controlName, index) => {
      const control = formControls[controlName]
      if (controlName !== 'message') {
        return (
          <div className={classes.InputContainer} key={controlName + index}>
            <Input
              label={control.label}
              value={control.value}
              placeholder={control.placeholder}
              required={control.required}
              isValid={control.isValid}
              errorMessage={control.errorMessage}
              shouldValidate={!!control.validation}
              onChange={event => changeHandler(event.target.value, controlName)}
              onBlur={
                control.validation ? event => onblurHandler(event.target.value)
                  : null
              }
            />
          </div>
        )
      }
    })
  }

  /**
    * Returns JSX (textarea).
    * @return {JSX | object} Textarea element.
    */

  const renderControlTextArea = () => {
    const control = formControls['message']
    return (
      <div className={classes.TextareaContainer}>
        <TextArea
          label={control.label}
          placeholder={control.placeholder}
          value={control.value}
          shouldValidate={!!control.validation}
          onChange={event => changeHandler(event.target.value, 'message')}
        />
      </div>
    )
  }

  const sendMessageHandler = event => {
    event.preventDefault()

    // scrolled up after send form
    let position = window.pageYOffset
    const interval = setInterval(() => {
      window.scrollTo(0, position < 25 ? 0 : position)
      position -= 25
      if (position < 0) clearInterval(interval)
    }, 20)

    const updatedControls = { ...formControls }
    const control = { ...updatedControls['email'] }
    const { isValid, errorMessage } = validate(
      control.value,
      {
        ...control.validation.onChange,
        ...control.validation.onBlur
      },
      fields.email
    )
    control.isValid = isValid
    control.errorMessage = errorMessage

    // send form if email field valid
    if (isValid) {
      const previousLocation = JSON.parse(localStorage.getItem('urls'))
      const getFormValue = getFormData(formControls)
      getFormValue.location = previousLocation ? previousLocation[0] : ''

      const reqToJira = fetch('https://backend.bluepes.com/.netlify/functions/api/', {
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        method: 'POST',
        body: JSON.stringify(getFormValue)
      })
      return reqToJira
        .then(res => {
          getStatus(res.status)
        })
        .catch(err => {
          getStatus(false)
          // eslint-disable-next-line no-console
          console.error(err)
        })
    }

    updatedControls['email'] = control
    return setContactForm(updatedControls)
  }

  return (
    <form className={classes.Form}>
      <div className={classes.FormHeader}>
        <h1>{title}</h1>
      </div>
      <div className={classes.InputsWrapper}>
        <fieldset>
          {renderControlsInput()}
          {renderControlTextArea()}
        </fieldset>
      </div>
      <input
        type="button"
        value={buttonName}
        onClick={sendMessageHandler}
      />
    </form>
  )
}

export default Form
