import React, { useEffect, Fragment } from 'react'
import { graphql, navigate } from 'gatsby'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import SEO from '../components/Seo'
import Form from '../components/Form/Form'
import Tabs from '../components/Tabs/Tabs'

/**
 * Contact us page
 */

const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_SITE_KEY

const ContactUs = ({ data }) => {
  const {
    seo: {
      frontmatter: {
        seo: seoInfo
      }
    },
    form: {
      frontmatter: {
        header,
        fields,
        buttonText,
      }
    },
    description: {
      frontmatter: {
        tabs
      }
    }
  } = data

  useEffect(() => {
    //  initialize the ReCaptcha
    loadReCaptcha(recaptchaSiteKey)
  }, [])

  const verifyCallback = recaptchaToken => {
    if (!recaptchaToken) {
    // eslint-disable-next-line no-console
      console.error(new Error(`Recaptcha token didn't recive`))
    }
  }

  // function check status of response, redirect to result screen
  const getStatusHandler = status => {
    if ([200, 201].includes(status)) {
      navigate(`/contact-us-success-screen/`)
    } else {
      navigate(`/contact-us-failure-screen/`)
    }
  }

  return (
    <Fragment>
      <SEO data={seoInfo} />
      <section className="contact-us-page">
        <ReCaptcha
          sitekey={recaptchaSiteKey}
          action='contactus'
          verifyCallback={verifyCallback}
        />
        <div className="cu-top-section"></div>
        <div className="cu-page-wrapper">
          <Form
            title={header}
            fields={fields}
            buttonName={buttonText}
            getStatus={getStatusHandler} />
          <Tabs description={tabs} />
        </div>
      </section>
    </Fragment>
  )
}

export default ContactUs

export const contactUsQuery = graphql`
  query {
    seo: markdownRemark(
      fileAbsolutePath: {regex: "/content/additional-content/contact-us/seo-contact-us.md/"}
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    form: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/contact-us/form.md/"}
    ) {
      frontmatter {
        header
        fields {
          name {
            label
            placeholder
          }
          email {
            label
            placeholder
            error1
            error2
          }
          phone {
            label
            placeholder
          }
          message {
            label
            placeholder
          }
        }
        buttonText
      }
    }
    description: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/contact-us/description.md/"}
    ) {
      frontmatter {
        tabs {
          title
          description
          icon {
            childImageSharp {
              fixed (quality: 100, base64Width: 100){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
