import React, { useState, useRef } from 'react'
import Img from 'gatsby-image'

import classes from './Tabs.module.scss'

const Tabs = ({ description }) => {
  const [shownTab, setShownTab] = useState(0)
  const isDescription = !!description && !!description.length

  const slideElements = []

  const animation = (slide1, slide2) => {
    for (let i = 1; i < 6; i++) {
      setTimeout(() => {
        slide1.style.opacity = 0
        slide2.style.opacity = i * 2 / 10
      }, 34 * i)
    }
  }

  const changeTabHandler = index => {
    setShownTab(index)
    slidesHandler(index)
  }

  const slidesHandler = index => {
    animation(
      slideElements[shownTab].current,
      slideElements[index].current
    )
  }

  return (
    isDescription && (
      <div className={classes.TabsWrapper}>
        <div
          className={classes.Icons}
          style={{
            '--left': `${ shownTab * (100 / description.length) }%`,
            '--width': `${ 100 / description.length }%`
          }}>
          {description.map((item, index) => (
            <div
              key={index}
              onClick={() => changeTabHandler(index)}
              className={[
                classes.IconWrapper,
                index === shownTab ? classes.Active : ''
              ].join(' ')}>
              {item.icon && <Img
                fixed={item.icon.childImageSharp.fixed}
                style={{
                  width: '44px',
                  height: '44px'
                }} />}
            </div>
          ))}
        </div>
        <div className={classes.Tab}>
          {description.map((item, index) => (
            <div
              key={index}
              ref={(slideElements[index] = useRef())}
              className={[
                classes.ContentWrapper,
                index === shownTab ? classes.Active : ''
              ].join(' ')}
              style={{ opacity: index === 0 ? 1 : 0 }}>
              <h2
                className={classes.Title}
                dangerouslySetInnerHTML={{ __html: item.title }} />
              <p
                className={classes.Description}
                dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          ))}
        </div>
      </div>
    )
  )
}

export default Tabs
