import React from 'react'

import classes from './Input.module.scss'
import Star from '../../../assets/icons/star.svg'

/**
 * Returns Input component
 * @param {string} label: label text
 * @param {string} value
 * @param {string} placeholder
 * @param {boolean} required: if star will shown
 * @param {boolean} isValid
 * @param {string} errorMessage: error message text
 * @param {function} onChange
 * @param {function} onBlur
 */

const Input = ({
  label,
  value,
  placeholder,
  required = false,
  isValid = true,
  errorMessage,
  shouldValidate,
  onChange,
  onBlur
}) => {
  const cls = [classes.Input]

  if (!isValid && shouldValidate) {
    cls.push(classes.Invalid)
  }

  return (
    <div className={cls.join(' ')}>
      <label className={classes.Label}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {required && <Star />}
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />

      {(!isValid) &&
        <div
          className={classes.ErrorMessage}
          dangerouslySetInnerHTML={{ __html: errorMessage }} />
      }
    </div>
  )
}

export default Input
