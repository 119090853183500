import React from 'react'

import classes from './Textarea.module.scss'

/**
 * Returns Textarea component
 * @param {string} label: label text
 * @param {string} value
 * @param {string} placeholder
 * @param {function} onChange
 */

const TextArea = ({ label, value, placeholder, onChange }) => (
  <div className={classes.TextArea}>
    <label className={classes.Label}>
      {label}
    </label>
    <textarea
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  </div>
)

export default TextArea
