// eslint-disable-next-line
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

/**
 * Returns controls validation
 * @param {string} Value of the field.
 * @param {object} Contains validaton info.
 * @returns {object | boolean} Is controls valid.
 */

export const validate = (value, validation = null, errors) => {
  let isValid = true
  let errorMessage = ''

  if (!validation) {
    return { isValid, errorMessage }
  }

  // check required field is not empty
  if (validation.required) {
    isValid = value.trim() !== '' && isValid
    if (!isValid) {
      errorMessage = errors.error1
      return { isValid, errorMessage }
    }
  }

  // check email format valid
  if (validation.isEmail && value.trim() !== '') {
    isValid = emailRegex.test(value)
    if (!isValid) errorMessage = errors.error2
  }

  return { isValid, errorMessage }
}
